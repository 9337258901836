<template>
  <div class="row">
    <div class="col-md-3 sidebar">
      <div class="row">
        <div class="col-md-12">
          <img class = "bio" src="../assets/images/profile_color_square.png" width="250">
        </div>
      </div>
      <div class = "row">
        <div class="col-md-12">
        <div class="infoitem" id="affiliations">
          <div class="infoheader">Assistant Professor
          <hr>
          </div>
         <a href="http://www.ucsb.edu/">University of California Santa Barbara</a><br>
         <a href="http://www.mat.ucsb.edu/">Media Arts and Technology</a><br>
         <a href="http://www.cs.ucsb.edu/">Computer Science (by courtesy)</a><br>
         <a href="http://ecl.mat.ucsb.edu">Expressive Computation Lab</a><br>
         <a href="http://www.civc.ucsb.edu/">Center for Visual and Interactive Computing (CIVC)</a><br>
       </div>
       </div>
     </div>
     <div class="row">
      <div class="col-md-12">
        <div class="infoitem" id="contact">
          <div class="infoheader">Contact Information
          <hr>
          </div>
          Elings Hall, Room 2215<br>
          Building 266 <br>
          University of California, Santa Barbara <br>
          Santa Barbara, CA 93106<br>
          Office Hours: Wed 11:00 AM-12:00 PM (by appointment only)<br>
          <a href="mailto:jmjacobs@mat.ucsb.edu" style="text-decoration:none">jmjacobs@mat.ucsb.edu

          </a><br>
           <a href="cv_public.pdf">CV</a> | <a href="https://scholar.google.com/citations?user=z0C-QBoAAAAJ&hl=en">Google Scholar</a> &nbsp;
           <a href="http://twitter.com/#!/@jsquare" style="text-decoration:none">
            <logo-twitter-icon w='15' h='15'/>
          </a><a href="http://www.instagram.com/expressivecomputationlab" style="text-decoration:none">
            <logo-instagram-icon w='15' h='15'/>
          </a> <a href="http://github.com/pixelmaid" style="text-decoration:none">
            <logo-github-icon w='15' h='15'/>
          </a>
        </div>

        <div class="infoitem" id="teaching">
          <div class="infoheader">Teaching
          <hr>
          </div>
          <div class="row">
            <div class="col-md-3">
              Winter 2023
            </div>
            <div class="col-md-9">
              <a href="https://sites.google.com/view/mat237-winter-23">Designing Expressive Technologies</a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              Spring 2024
            </div>
            <div class="col-md-9">
              <a href="https://sites.google.com/view/mat238-spring-24">Computational Fabrication</a>
            </div>
          </div>
        </div>
         <div class="infoitem" id="teaching">
         <div class="infoheader">Students and Postdocs
          <hr>
          </div>
           <div class="row">
            <div class="col-md-12">
             <a href="http://sambourgault.com/">Samuelle Bourgault</a>, <a href="https://ashleyanndv3.wixsite.com/mysite">Ashley DelValle</a>, <a href="https://amcard.myportfolio.com//">Ana María Cárdenas</a>, <a href="https://merttoka.com/">Mert Toka</a>, <a href="https://devnfrost.com">Devon Frost</a>, <a href="https://paytoncroskey.com/">Payton Croskey</a>, <a href="https://em-yu.github.io/">Emilie Yu</a>
          </div>
          </div>
        
        <div class="infoheader">Alumni
          <hr>
          </div>
          <div class="row">
            <div class="col-md-12">
            <a href="https://marehirsch.com/">Mare Hirsch</a>, <a href="https://www.soniahashim.co/">Sonia Hashim</a>, <a href="https://philip.news/about">Philip Kobernik</a>, <a href="https://www.yanchenlu.com/">Yanchen Lu</a>
          </div>
          </div>
        </div>
        <div class="infoitem" id="travel">
          <div class="infoheader">Upcoming Travel
          <hr>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!--<p>May 22nd-28th: Haystack Labs, Haystack Mountain School of Crafts, Deer Isle, Maine</p>
              <p>July 20th-21st: Ceramics Buisness Development Workshop, University of New Mexico, Albuquerque, New Mexico</p>
              <p>September 30th-October 2nd: Sketching in Hardware, Dublin Ireland</p>
              <p>December 4th-December 14th: Santiago, Chile</p>
              <p>January 19th: Chicago, USA.</p>-->
              <p>April 11th: COSA Open Source Arts Contributors Conference, Denver, USA.</p>
              <p>April 24th: Asilomar Microcomputer Workshop, Asilomar, California, USA.</p>
              <p>May 16th: ACM CHI 2024, Honolulu, Hawaii, USA.</p>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
      </div>
    </div>
  </div>
  <div class="col-md-9">
    <div class="row">
      <div id="about" class="col-md-12">
        <p>I am an Assistant Professor at the <a href="http://ucsb.edu">University of California Santa Barbara</a> in <a href="http://mat.ucsb.edu">Media Arts and Technology</a> and <a href="http://cs.ucsb.edu">Computer Science</a> (by courtesy), where I direct the <a href="http://ecl.mat.ucsb.edu">Expressive Computation Lab</a>. I work across the fields of computational art and design, human computer interaction, and systems engineering. My research lab investigates ways to support expressive computer-aided design, art, craft, and manufacturing by developing new computational tools, abstractions, and systems that integrate emerging forms of computational creation and digital fabrication with traditional materials, manual control, and non-linear design practices. More broadly, we examine how we can enable art and design professionals to leverage their domain expertise to develop personal software tools. I received my Ph.D. from the Massachusetts Institute of Technology's Media Lab and completed my postdoctoral research in the Computer Science Department at Stanford University. I also received an M.F.A. and B.F.A from Hunter College and the University of Oregon respectively. My research has been presented at international venues including SIGGRAPH, DIS, CACM, Ars Electronica and CHI.</p>

      </div>
    </div>
    <div class = 'row'>
     <news></news>
     <div class = col-md-12><router-link to="/news">See all news</router-link></div>        
   </div>
   <div class="row">
    <projects></projects> 
  </div>
   <div class="row">
    <publications></publications> 
  </div>
</div>
</div>


</template>

<script>
  import News from '@/components/News'
  import Publications from '@/components/Publications'
  import Projects from '@/components/Projects'

  export default {
    name: 'home',
    components: {
      News,
      Publications,
      Projects
    },


 // mounted: function () {

  //},
  methods: {
    shuffle: function (array) {
      var currentIndex = array.length; var temporaryValue; var randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    }

  },

  computed: {

  }
}
</script>
