<template>
  <div class ='col-md-12 mb-4'>
    <div class = "row">
     <div class ='col-md-2'>
       <img class = 'thumb' :src='thumb'>
     </div>
     <div class ='col-md-10'>
      <span v-if="pagelink"><a :href="pagelink"><b>{{title}}</b></a></span>
      <span v-else-if="status == 'forthcoming'"><b>{{title}}</b></span>
      <span v-else><a :href='"http://ecl.mat.ucsb.edu/publication/"+slug'><b>{{title}}</b></a></span>
      <br/>
      <span v-for='(author,index) in authorData' :key='author.id'>
        <a :href='author.url'>{{author.name}}</a>
        <span v-if="index < authorData.length - 2">, </span>
        <span v-if="index == authorData.length -2">, and </span>
      </span>
      <br/>
      <span v-if="status == 'forthcoming'"><i>To appear in </i></span><span>{{venue}} {{month}}, {{year}}</span>
      <br/>
      <template v-if="status != 'forthcoming'">
        <span v-for='(w,index) in web' :key='w.link'>
          <router-link v-if="w.text == 'BibTeX'" :to='{name: "bibtex", params: {data: w.link}}'>
              {{w.text}}
          </router-link>
          <a v-else :href='w.link'>{{w.text}}</a>
        <span v-if="index < web.length - 1"> | </span>
        </span>
      </template>
     
      <template v-if="award != ''">
         <br/>
      <span style="display: inline-block; margin-top: 8px; padding-top: 3px;" class="label paperaward"><b>{{award}}</b></span>
    </template>
    </div>
  </div>
</div>
</template>

<script>
import AirtableService from '@/services/AirtableService'

export default {
  name: 'PubCard',
  props: {
    title: String,
    slug: String,
    month: String,
    year: String,
    venue: String,
    pdf: String,
    doi: String,
    bibtex: String,
    thumb: String,
    pagelink: String,
    award: String,
    status: String,
    authors: {
      type: Array
    },
    web: {
      type: Array
    }
  },

  data () {
    return {
      airtableAuthorResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getAuthorList () {
      try {
        self.airtableAuthorResponse = await AirtableService.getAuthorList(self.authors)
      } catch (err) {
        console.log(err)
      }
    }
    getAuthorList()
  },

  computed: {
    authorData () {
      const self = this
      var authorList = []
      for (var i = 0; i < self.airtableAuthorResponse.length; i++) {
        const author = AirtableService.setupAuthor(self.airtableAuthorResponse[i])
        authorList.push(author)
      }
      const authorResult = AirtableService.sortAuthorList(authorList, self.authors)
      return authorResult
    }
  }

}
</script>
