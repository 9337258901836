import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSimpleMarkdown from 'vue-simple-markdown'
import { BootstrapVue, NavbarPlugin, IconsPlugin } from 'bootstrap-vue'
import AllLogoIcons from 'vue-ionicons/dist/ionicons-logo'
import AllMaterialIcons from 'vue-ionicons/dist/ionicons-md'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/custom.scss'
import 'vue-ionicons/ionicons.scss'
import VueResource from 'vue-resource'
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'


Vue.use(Meta)
Vue.use(BootstrapVue)
Vue.use(VueResource)
Vue.use(AllMaterialIcons)
Vue.use(AllLogoIcons)
Vue.use(IconsPlugin)
Vue.use(VueSimpleMarkdown)
Vue.use(NavbarPlugin)
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_ANALYTICSKEY,
    params: {
      send_page_view: false
    }
  }
}, router)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
