<template>
	<div class ='col-md-12'>
		<h2>Recent News</h2>
		<div class="row" v-for='newsItem in news' :key="newsItem.title">
			
			<div class ='col-md-2'>
				
				<p class = 'news'>{{newsItem.date}}</p>
			</div>
			<div class = 'col-md-10'>
				<p class = "news"><vue-simple-markdown :source=newsItem.text></vue-simple-markdown></p>
			</div>
		</div>
	</div>
</template>

<script>
import AirtableService from '@/services/AirtableService'

export default {
		name: 'news',	

		data () {
			return {
				airtableResponse: [],

			}
		},

		mounted: function () {
			const self = this
			async function getNews () {
				try {
					const response = await AirtableService.getNews()
					self.airtableResponse = response.data.records
				} catch (err) {
					console.log(err)
				}
			}


			getNews()
		},

		computed: {
			news () {
				const self = this
				const newsList = []
				for (var i = 0; i < self.airtableResponse.length; i++) {
					if (self.airtableResponse[i].fields.published) {
						const dateArray = self.airtableResponse[i].fields.date.split("-");
						const day = dateArray[2]
						const month = dateArray[1]
						const year = dateArray[0]
						const d = new Date(month+"-"+day+"-"+year);
						const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
						const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
						const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
						const formattedDate = `${mo}-${da}-${ye}`
						const newsItem = {
							title: self.airtableResponse[i].fields.title,
							text: self.airtableResponse[i].fields.snippet,
							date: formattedDate,
							numericDate: d
						}
						newsList.push(newsItem)
					}
				}
				newsList.sort(function (a, b) {
					if (a.numericDate > b.numericDate) {
						return -1
					} else {
						return 1
					}
				})
				if(this.$route.name == "news"){
					return newsList
				}
				else{
					return newsList.slice(0,5);
				}
			}
		}
	}

</script>