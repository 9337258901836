import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'
import News from './components/News.vue'
import NavShell from './components/NavShell.vue'
import Bibtex from './components/Bibtex.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'navshell',
      component: NavShell,
      children: [
        {
          path: '/',
          name: 'home',
          component: Home
        },
        {
          path: '/home',
          name: 'home',
          component: Home
        },{
          path: '/news',
          name: 'news',
          component: News
        }
        ]
    },{
      path: '/bibtex/:data',
      name: 'bibtex',
      props: true,
      component: Bibtex
    }]

})
