<template>
        <router-view> </router-view>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      showNav: true,
      logo: require('./assets/images/logo.png')
    }
  },

  metaInfo () {
    return {
      title: 'Jennifer Jacobs',
      htmlAttrs: {
      },
      headAttrs: {
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Assistant Professor Media Arts and Technology at the University of California Santa Barbara' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  },

  mounted: function () {
    const path = localStorage.getItem('path')
    if (path) {
      localStorage.removeItem('path')
      this.$router.push(path)
    }
  }
}
</script>

<style>

</style>
