<template>
    <div class = 'container' id ='main'>
        <div class = 'row'>
            <div class = "col-md-8 mb-2" id='title'>
                <h1>Jennifer Jacobs</h1>
            </div>
            <div class = "col-md-auto mb-2 vertical_center affiliation">
              <div class = "row flex-nowrap">
            <div class = "col-md-3 mat_affiliation">
              <a href='https://www.mat.ucsb.edu/'><img src="../assets/images/MAT_logo_150x54.png"/></a>
            </div>
             <div class = "col vertical_center logo_break">|</div>
            <div class = "col-md-8 vertical_center ucsb_affiliation">
              <a href='https://www.ucsb.edu/'><img src="../assets/images/UC_Santa_Barbara_Wordmark_Black_RGB.png"/></a>
            </div>
          </div>
            </div>
        </div>
        <div class = 'row'>
            <div class = "col-md-12 mb-3">
                   <!-- <b-navbar toggleable="lg" type="light" >

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
    <b-navbar-nav>
                    <b-nav-item href="#">
                        <router-link to="/home">Lab</router-link>
                    </b-nav-item>
                    <b-nav-item href="#">
                        <router-link to="/people">People</router-link>
                    </b-nav-item>
            
                    <b-nav-item href="#">
                        <router-link to="/publications">Publications</router-link>
                    </b-nav-item>
                     <b-nav-item href="#">
                        <router-link to="/courses">Courses</router-link>
                    </b-nav-item>
                    <b-nav-item href="#">
                        <router-link to="/openings">Openings</router-link>
                    </b-nav-item>
                    <b-nav-item href="#">
                        <router-link to="/contact">Contact</router-link>
                    </b-nav-item>
 </b-navbar-nav>
    </b-collapse>
  </b-navbar>-->          </div>
        </div>
        
            <router-view />
    </div>
</template>

<script>

export default {
  name: 'navshell',
  data () {
    return {
      showNav: true
    }
  },

  mounted: function () {
    const path = localStorage.getItem('path')
    if (path) {
      localStorage.removeItem('path')
      this.$router.push(path)
    }
  }
}
</script>

<style>

</style>
