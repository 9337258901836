<template>
    <div class = 'row'>
        <div class ='col-md-12 mb-3'>
            <div class="row">
        
  
            
        <div class ='col-md-2'>
           <a :href='"http://ecl.mat.ucsb.edu/project/"+slug' class='button is-fullwidth'><img class = 'thumb' :src='thumb'></a>
        </div>
   
        <div class ='col-md-10'>
            <h4><a :href='"http://ecl.mat.ucsb.edu/project/"+slug' class='button is-fullwidth'>{{title}}</a></h4>

            <p>{{snippet}}</p>
        </div>
            </div>

</div>
    </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    title: String,
    slug: String,
    date: String,
    abstract: String,
    snippet: String,
    thumb: String
  }
}
</script>
